import { RECALL_CLASSIFICATIONS } from '../../../../misc/commons'
import VueDropzone from 'vue2-dropzone'
import userServices from '../../../../services/user'
import ResponseModal from '../ResponseModal'
import S3 from 'aws-s3'
export default {

    components: {
        VueDropzone,
        ResponseModal
    },
    sprops: ['infoData', 'recallMetaData', 'recallDisabled'],
    data() {
        return {
            item: {
                suppliername: null,
                name: null,
                recallid: null,
                id: null,
                response_email: null,
                documents: null,
                createdBy: null,
                supplierEmail:null,
            },
            formType: 'INSERT',
            recallId: null,
            errorList: [],
            RecallsList: [],
            filesUpload: [],
            filespaths: [],
            s3upload: [],
            IsUpdated:false,
            filesUploadUpdate: [],
            filesUploadOriginal: [],
            respModalKey: 0,
            cnt: 0,
            uploadcnt: 0,
            thumbnailIndex: -1,
            updateRecallId: '',
            
        }
    },
    mounted() {
        this.info = _.cloneDeep(this.infoData)
        localStorage.removeItem('FileContent')
        //localStorage.removeItem('FilePath')
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'Add Recall Response',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class:"card-title"
                }
            } 
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Recall Response',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
        S3Url()        
        {
            return 'https://qa-er-data.s3.us-east-2.amazonaws.com'
        },
        FileNameByGUID()
         {  
            var Tdate = new Date();
            var txt = Tdate.valueOf()
             
            return  txt.toString().substr(7)
          }  ,
          GetUserId(){
              return localStorage.getItem('userId');
          }
    },
    methods:{
        removeDocument(index) {
            if (this.formType == "INSERT") {
                
                this.filesUpload.splice(index, 1)
                this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
                var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                storedNames.splice(index, 1)
                localStorage.removeItem('FileContent')
                localStorage.setItem("FileContent", JSON.stringify(storedNames));
            }
            else {
                //
                let Olen = this.filesUpload.length
                let Nlen = this.filesUploadUpdate.length
                let NIndex = Olen - Nlen
                let NIndexA = Olen - NIndex
                if ((index + 1) > NIndex) {
                    let A = index - NIndex
                    this.filesUploadUpdate.splice(A, 1)
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    storedNames.splice(A, 1)
                    localStorage.removeItem('FileContent')
                    localStorage.setItem("FileContent", JSON.stringify(storedNames));
                }
                else{
                    this.filesUploadOriginal.splice(index, 1)
                }
                this.filesUpload.splice(index, 1)
                
                this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
            }
        },
        GetContentType(type,ext){
            if(type == 'application/vnd.ms-excel')
            {
                return 'application/'  + ext;
            }
            else if(type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            {
                return 'application/' + ext;
            }
            else if(type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
            {
                return 'application/'  + ext;
            }
            else if(type == 'image/svg+xml')
            {
                return 'image/'  + ext;
            }
            else if(type == 'text/plain')
            {
                return 'application/'  + ext;
            }
            else 
            {
                return type;
            }
        },
        async handleFilesUpload() {
            //
            const config = {
                bucketName: 'qa-er-data',
                dirName: this.GetUserId,
                region:'us-east-2',
                accessKeyId:'AKIA3PVXGLRQ6AHCKHWW',
                secretAccessKey:'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',
                s3Url: this.S3Url,
            }
            const S3Client = new S3(config);
            let uploadedFiles = this.$refs.files.files
            this.uploadcnt = 0;
            this.cnt = 0;
            this.uploadcnt = uploadedFiles.length
            this._loadingStart()
            for ( let i = 0; i < uploadedFiles.length; i++ ) {
                let fileNameUpload = uploadedFiles[i].name.split(".")[0] + "_" + this.FileNameByGUID;
                this.s3upload.push({
                    name: fileNameUpload,
                })
                debugger
            S3Client.uploadFile(uploadedFiles[i],fileNameUpload,this.GetContentType(uploadedFiles[i].type,uploadedFiles[i].name.split('.')[1])).then(data=>
                {
                    
                    this.IsUpdated = true;
                    this.cnt++;
                    var userD = [];
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    debugger
                    if (storedNames == null) {
                        userD[0] = data.location
                    }
                    else    
                    {
                     for ( let i = 0; i < storedNames.length; i++ ) {
                            userD[i] = storedNames[i];
                        }   
                        userD[storedNames.length] = data.location;
                    }
                    localStorage.setItem("FileContent", JSON.stringify(userD));    
                    if(this.cnt == this.uploadcnt) {
                        this._loadingEnd()
                    }
                }).catch(err=>{
                    debugger
                })
            }
            
              for ( let i = 0; i < uploadedFiles.length; i++ ) {
                
                  if (this.formType == "INSERT") {
                    this.filesUpload.push({
                        name: uploadedFiles[i].name,
                        size: uploadedFiles[i].size,
                        type: uploadedFiles[i].type,
                        file: uploadedFiles[i],
                    })
                  }
                  else  {
                      
                    this.IsUpdated = true;
                    this.filesUpload.push({
                        name: uploadedFiles[i].name,
                        type: uploadedFiles[i].type,
                        size: uploadedFiles[i].size,
                        path: '',
                    })
                    this.filesUploadUpdate.push({
                        name: uploadedFiles[i].name,
                        size: uploadedFiles[i].size,
                        type: uploadedFiles[i].type,
                        file: uploadedFiles[i],
                    })
                  }
            }
            this.$refs.files.value = ''
        },
        handleChangeThumbnail(val) {
            this.thumbnailIndex = val
            this.filesUpload.forEach((x, index) => {
                if(index === val) {
                    x.is_thumbnail = true
                } else {
                    x.is_thumbnail = false
                }
            })
        },
        forceRerenderInsert(list) {
            this.formType = 'INSERT'
            list.forEach((x, i) => 
            {
                this.RecallsList.push(x.userRecallId.trim())
            });
            this.$refs.modal.show()
        },
        forceRerenderUpdate(val,list) {
            this.formType = 'UPDATE'
            
            list.forEach((x, i) => 
            {
                this.RecallsList.push(x.userRecallId.trim())
            });
            this.updateRecallId = val.userRecallId.trim()
            this.item = {
                suppliername: val.suppliername,
                name: val.name,
                recallid: val.userRecallId,
                id: val.recallid,
                status: val.status,
                response_email: val.supplierEmail,
                documents: null,
                createdBy: null,
                supplierEmail: val.supplierEmail,
            }
            val.Files.forEach((x, i) => {
                if (x.FilePath != "Empty") {
                    
                    this.filesUpload.push({
                        name: x.FileName.substr(x.FileName.lastIndexOf('/') + 1),
                        extension: x.FileName.substr(x.FileName.lastIndexOf('/') + 1).split(".")[1],
                        type: x.FileType,
                        size: x.fileSize,
                        path: x.FilePath,
                    })
                    this.filesUploadOriginal.push({
                        name: x.FileName.substr(x.FileName.lastIndexOf('/') + 1),
                        extension: x.FileName.substr(x.FileName.lastIndexOf('/') + 1).split(".")[1],
                        type: x.FileType,
                        size: x.fileSize,
                        path: x.FilePath,
                    })        
                }
        })
        
            this.$refs.modal.show()
        },
        
        async createRecall(e) {
            const isValid = await this.$refs['observer'].validate()
            if(isValid)
            {  
                if(!this.RecallsList.includes(this.item.recallid.trim()))
                {
                    // const config = {
                    //     bucketName: 'qa-er-data',
                    //     dirName: this.GetUserId,
                    //     region:'us-east-2',
                    //     accessKeyId:'AKIA3PVXGLRQ6AHCKHWW',
                    //     secretAccessKey:'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',
                    //     s3Url: this.S3Url,
                    //     type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    // }
                    // const S3Client = new S3(config);
                    // var fileP =[];
                    // //var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    // debugger
                    // if (!this.filesUpload.length == 0) {
                        
                    //     this.uploadcnt = 0;
                    //     this.cnt = 0;
                    //     this.uploadcnt = this.filesUpload.length
                    //     this._loadingStart()
                    //     for ( let i = 0; i < this.filesUpload.length; i++ ) {
                    //         debugger
                    //         let fileNameUpload = this.filesUpload[i].name.split(".")[0] + "_" + this.FileNameByGUID + "." + this.filesUpload[i].name.split(".")[1]
                    //         this.s3upload.push({
                    //             name: fileNameUpload,
                    //         })
                    //     S3Client.uploadFile(this.filesUpload[i],fileNameUpload).then(data=>
                    //         {
                    //             var userD = [];
                    //             var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    //             if (storedNames == null) {
                    //                 userD[0] = data.location
                    //             }
                    //             else    
                    //             {
                    //             for ( let i = 0; i < storedNames.length; i++ ) {
                    //                     userD[i] = storedNames[i];
                    //                 }   
                    //                 userD[storedNames.length] = data.location;
                    //             }
                    //             this.IsUpdated = true;
                    //             this.cnt++;
                    //             localStorage.setItem("FileContent", JSON.stringify(userD));    
                                
                    //             if(this.cnt == this.uploadcnt) {
                    //                 debugger
                    //                 storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    //                 this.filesUpload.forEach((x, i) => {
                    //                     let s3U = this.S3Url + "/"
                    //                     let path = '';
                    //                     storedNames.forEach((y, i) => {
                    //                         var fn = x.name.split(".")[0]
                    //                         if(y.toString().includes(fn))
                    //                         {
                    //                             path = y
                    //                         }
                    //                         })
                    //                     var fName = path.split(s3U)[1];
                    //                     fileP.push({
                    //                         "filePath": path,
                    //                         "fileName": fName,
                    //                         "fileType": x.type,
                    //                         "fileSize": x.size
                    //                     })
                    //                 })
                    //                 debugger
                    //                         var uId = this.GetUserId
                    //                         var itemData ={
                    //                             "Files": fileP,
                    //                             "RecallId": null,
                    //                             "supplierEmail": this.item.response_email == null ? "" : this.item.response_email ,
                    //                             "supplierName": this.item.suppliername,
                    //                             "supplierRecallId": this.item.recallid,
                    //                             "supplierRecallName": this.item.name,
                    //                             "userId": uId,
                    //                             'userRecallId': this.item.recallid,
                    //                             "responceDate": "10/10/2020",
                    //                             "responceStatus": "DRAFT",
                    //                             "createdAt": "10/10/2020",
                    //                             "messageeId": ""
                    //                             }
                    //                             //debugger
                    //                         userServices.createRecall(itemData).then(resp=>{          
                    //                             if(resp.data.data){   
                    //                                 this.$refs.modal.hide()
                    //                                 this._loadingEnd()
                    //                                 this.$parent.$options.parent.fetchData("Recall created successfully")
                    //                             }
                    //                         })
                    //                 //
                    //             }
                    //         })
                    //     }

                    var fileP =[];
                    var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                    debugger
                    this.filesUpload.forEach((x, i) => {
                                let s3U = this.S3Url + "/"
                                let path = '';
                                storedNames.forEach((y, i) => {
                                    var fn = x.name.split(".")[0]
                                    if(y.toString().includes(fn))
                                    {
                                        path = y
                                    }
                                    })
                                var fName = path.split(s3U)[1];
                                fileP.push({
                                    "filePath": path,
                                    "fileName": fName,
                                    "fileType": x.type,
                                    "fileSize": x.size
                                })
                            })
                            debugger
                                    var uId = this.GetUserId
                                    var itemData ={
                                        "Files": fileP,
                                        "RecallId": null,
                                        "supplierEmail": this.item.response_email == null ? "" : this.item.response_email ,
                                        "supplierName": this.item.suppliername,
                                        "supplierRecallId": "11",
                                        "supplierRecallName": this.item.name,
                                        "userId": uId,
                                        'userRecallId': this.item.recallid,
                                        "responceDate": "10/10/2020",
                                        "responceStatus": "DRAFT",
                                        "createdAt": "10/10/2020",
                                        "messageeId": ""
                                        }
                                        //debugger
                                    userServices.createRecall(itemData).then(resp=>{          
                                        if(resp.data.data){   
                                            this.$refs.modal.hide()
                                            this.$parent.$options.parent.fetchData("Recall created successfully")
                                        }
                                    })
                    if (this.filesUpload.length == 0) {
                        var uId = this.GetUserId;
                        var fileP = [{
                            "filePath": "Empty",
                            "fileName": "",
                            "fileType": "",
                            "fileSize": ""
                        }]
                        var itemData ={
                            "Files": fileP,
                            "RecallId": null,
                            "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                            "supplierName": this.item.suppliername,
                            "supplierRecallId": this.item.recallid,
                            "supplierRecallName": this.item.name,
                            "userId": uId,
                            'userRecallId': this.item.recallid,
                            "responceDate": "10/10/2020",
                            "responceStatus": "DRAFT",
                            "createdAt": "10/10/2020",
                            "messageeId": ""
                            }
                            
                        userServices.createRecall(itemData).then(resp=>{          
                            
                            if(resp.data.data){   
                                this.$refs.modal.hide()
                                this.$parent.$options.parent.fetchData("Recall created successfully")
                            }
                        })   
                    }
                }
                else
                {
                    this._showToast("Recall Id is already existed.", { variant: 'danger',title:'Error' })
                }
            }
        },
        async updateRecall(e) {
            var isValid = await this.$refs['observer'].validate()
            if(isValid)
            {  
                
                if(!this.RecallsList.includes(this.item.recallid.trim()) || this.updateRecallId.trim() == this.item.recallid.trim())
                {
            if (this.IsUpdated) {
                var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                // const config = {
                //     bucketName: 'qa-er-data',
                //     dirName: this.GetUserId,
                //     region:'us-east-2',
                //     accessKeyId:'AKIA3PVXGLRQ6AHCKHWW',
                //     secretAccessKey:'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',
                //     s3Url: this.S3Url
                // }
                // const S3Client = new S3(config);
                var fileP =[];
                this.filesUploadUpdate.forEach((x, i) => {
                    let s3U = this.S3Url + "/"
                    let path = '';
                    storedNames.forEach((y, i) => {
                        var fn = x.name.split(".")[0]
                        if(y.toString().includes(fn))
                        {
                            path = y
                        }
                        })
                    var fName = path.split(s3U)[1];
                    fileP.push({
                        "filePath": path,
                        "fileName": fName,
                        "fileType": x.type,
                        "fileSize": x.size
                    })
                })
                this.filesUploadOriginal.forEach((x, i) => {
                    fileP.push({
                            "FilePath": x.path,
                            "FileName": x.name,
                            "FileType": x.type,
                            "fileSize": x.size
                        })
                    
                });
                var uId = this.GetUserId;
                var itemData ={
                    "Files": fileP,
                    "RecallId": this.item.id,
                    "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                    "supplierName": this.item.suppliername,
                    "supplierRecallId": this.item.recallid,
                    "supplierRecallName": this.item.name,
                    "userId": uId,
                    'userRecallId': this.item.recallid,
                    "responceStatus": 'DRAFT',
                    "createdAt": "10/10/2020",
                    "messageeId": ""
                    }
             
                userServices.updateRecall(itemData).then(resp=>{          
                    if(resp.data.data){   
                        this.$refs.modal.hide()
                        this._loadingEnd()
                        this.$parent.$options.parent.fetchData("Recall Updated successfully")
                    }
                })
                // this.uploadcnt = 0;
                //         this.cnt = 0;
                //         this.uploadcnt = this.filesUploadUpdate.length
                //         this._loadingStart()
                //         for ( let i = 0; i < this.filesUploadUpdate.length; i++ ) {
                //             let fileNameUpload = this.filesUploadUpdate[i].name.split(".")[0]  + "_" + this.FileNameByGUID//this.item.recallid;
                //             this.s3upload.push({
                //                 name: fileNameUpload,
                //             })
                //         S3Client.uploadFile(this.filesUploadUpdate[i],fileNameUpload).then(data=>
                //             {
                //                 var userD = [];
                //                 var storedNames = JSON.parse(localStorage.getItem("FileContent"));
                //                 if (storedNames == null) {
                //                     userD[0] = data.location
                //                 }
                //                 else    
                //                 {
                //                 for ( let i = 0; i < storedNames.length; i++ ) {
                //                         userD[i] = storedNames[i];
                //                     }   
                //                     userD[storedNames.length] = data.location;
                //                 }
                //                 this.IsUpdated = true;
                //                 this.cnt++;
                //                 localStorage.setItem("FileContent", JSON.stringify(userD));    
                //                 if(this.cnt == this.uploadcnt) {
                //                     storedNames = JSON.parse(localStorage.getItem("FileContent"));
                //                     this.filesUploadUpdate.forEach((x, i) => {
                //                         let s3U = this.S3Url + "/"
                //                         let path = '';
                //                         storedNames.forEach((y, i) => {
                //                             var fn = x.name.split(".")[0]
                //                             if(y.toString().includes(fn))
                //                             {
                //                                 path = y
                //                             }
                //                             })
                //                         var fName = path.split(s3U)[1];
                //                         fileP.push({
                //                             "filePath": path,
                //                             "fileName": fName,
                //                             "fileType": x.type,
                //                             "fileSize": x.size
                //                         })
                //                     })
                //                     this.filesUploadOriginal.forEach((x, i) => {
                //                         fileP.push({
                //                                 "FilePath": x.path,
                //                                 "FileName": x.name,
                //                                 "FileType": x.type,
                //                                 "fileSize": x.size
                //                             })
                                        
                //                     });
                //                     debugger
                //                         var uId = this.GetUserId;
                //                         var itemData ={
                //                             "Files": fileP,
                //                             "RecallId": this.item.id,
                //                             "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                //                             "supplierName": this.item.suppliername,
                //                             "supplierRecallId": this.item.recallid,
                //                             "supplierRecallName": this.item.name,
                //                             "userId": uId,
                //                             'userRecallId': this.item.recallid,
                //                             "responceStatus": 'DRAFT',
                //                             "createdAt": "10/10/2020",
                //                             "messageeId": ""
                //                             }
                                     
                //                         userServices.updateRecall(itemData).then(resp=>{          
                //                             if(resp.data.data){   
                //                                 this.$refs.modal.hide()
                //                                 this._loadingEnd()
                //                                 this.$parent.$options.parent.fetchData("Recall Updated successfully")
                //                             }
                //                         })
                //                 }
                //             }).catch(err=>{
                //                 debugger
                //             })
                //         }
                       
                        }
            //
            if (!this.IsUpdated) {
                var uId = this.GetUserId;
                var fileP =[];
                if (this.filesUploadOriginal.length >0) {
                    this.filesUploadOriginal.forEach((x, i) => {
                        fileP.push({
                                "FilePath": x.path,
                                "FileName": x.name,
                                "FileType": x.type,
                                "fileSize": x.size
                            })
                        
                    });    
                }
                else {
                    fileP = [{
                        "filePath": "Empty",
                        "fileName": "",
                        "fileType": "",
                        "fileSize": ""
                    }]
                }
                
                            var itemData ={
                                "Files": fileP,
                                "RecallId": this.item.id,
                                "supplierEmail": this.item.response_email == null ? "" : this.item.response_email,
                                "supplierName": this.item.suppliername,
                                "supplierRecallId": this.item.recallid,
                                "supplierRecallName": this.item.name,
                                "userId": uId,
                                'userRecallId': this.item.recallid,
                                "responceStatus": 'DRAFT',
                                "createdAt": "10/10/2020"
                                }
                                //
                            userServices.updateRecall(itemData).then(resp=>{          
                               
                                if(resp.data.config.data){   
                                    this.$refs.modal.hide()
                                    this.$parent.$options.parent.fetchData("Recall Updated successfully")
                                }
                            })
            }
        }
        else
        {
            this._showToast("Recall Id is already existed.", { variant: 'danger',title:'Error' })
        }
        }
        },
        removeDomain(index) {
            this.item.domains.splice(index, 1)
        },        
        addDomain() {
            this.item.domains.push('')
        }
    }
}