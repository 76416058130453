import userServices from '../../../../services/user'
//import emailjs from 'emailjs-com';
export default {
    components: {
    },
    data() {
        return {
            // item: {
            //     suppliername: null,
            //     name: null,
            //     recallid: null,
            //     response_email: null,
            //     documents: null,
            //     createdBy: null,
            // },
            item:[],
            admin:[],
            formType: 'INSERT',
        }
    },
    mounted() {
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'Would you like to send recall response to supplier?',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            } 
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Supplier',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },  
    },
    methods:{
        sendEmail(e) {
            var toEmail = this.item.supplierEmail
            var fileP =[];
            this.item.Files.forEach((x, i) => {
                if(x.FilePath != "Empty")
                {
                    fileP.push({
                        "FileName": x.FileName,
                        "FilePath": x.FilePath
                    })
                }     
            });
            //debugger
                var itemData = 
                    {
                        "Subject" : "NotiSphere ER Response",
                        "FromEmail": "support@notisphererecalls.com",
                        "ToEmail": toEmail,
                        "Body": this.$refs.modal.$refs.body.children[3].innerHTML,
                        "FileAttachment": fileP,
                        "RecallId":this.item.recallid,
                    }
                    debugger
                userServices.sendResponse(itemData).then(resp=>{          
                    debugger
                    //if(resp.data.data == "Success"){   
                        this.updateRecall(resp.data.data)
                        //this._showToast("Response Sent", { variant: 'success' })
                        
                    //}
                    // else    {
                    //     this._showToast("Response not Sent", { variant: 'warning' })
                    // }
                })   
          },
          updateRecall(msgID){
            var itemData ={
                "Files": this.item.Files,
                "RecallId": this.item.recallid,
                "supplierEmail": this.item.supplierEmail,
                "supplierName": this.item.suppliername,
                "supplierRecallId": this.item.userRecallId,
                "supplierRecallName": this.item.name,
                "userId": this.item.userId,
                'userRecallId': this.item.supplierRecallId,
                "responceStatus": "SENT",
                "createdAt": "10/10/2020",
                "messageeId":msgID
                }
                debugger
            //userServices.updateRecall(itemData).then(resp=>{          
                debugger
                this.$refs.modal.hide()
                this.$parent.$options.parent.fetchData("Response sent successfully")
                //if(resp.data.data){   
                    //this._showToast("Recall Updated successfully", { variant: 'success' })
                    //this.$refs.modal.hide()
                    //this.$parent.$options.parent.fetchData("Response sent successfully")
                    //this._showToast("Response Sent", { variant: 'success' })
                //}
            //})
          },
        forceRerenderInsert(val) {
            //debugger
            this.item = val
            var storedNames = JSON.parse(localStorage.getItem("userD"));
                this.admin.name = storedNames[1] + " "+ storedNames[2];
                this.admin.email = storedNames[3];
                this.admin.title = storedNames[4];
                this.admin.companyname = storedNames[5];
                this.admin.phoneno = storedNames[6];
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        cancel() {
            this.$refs.modal.hide()
        }
    }
}