
import SupplierModal from './SupplierModal'
import ResponseModal from './ResponseModal'
import { RECALL_STATUS, RECALL_CLASSIFICATIONS } from '../../../misc/commons'
import userServices from '../../../services/user'
//import { base64StringToBlob } from 'blob-util';
export default {
    components: {
        SupplierModal,
        ResponseModal
    },
    data() {
        return {
            total: 0,
            items: [],
            listSupplier: [],
            // search: {
            //     status: null,
            //     category: null,
            //     supplierOrgId: null,
            // },
            searchQuery: {},
            header: [
                {
                    label: 'Status',
                    name: 'status',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Supplier Name',
                    name: 'suppliername',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Recall ID',
                    name: 'userRecallId',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Name',
                    name: 'name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: 'Documents',
                    name: 'documents',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes:'wordbreakall vertical-align-top'
                },
                {
                    label: 'Response Date',
                    name: 'date',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    //row_classes: 'customalign-15percent wordbreakall'
                },
                {
                    label: '',
                    name: 'action',
                    slot_name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ],

            // listCategories: [],
            recall_status: RECALL_STATUS,
            recall_classification: RECALL_CLASSIFICATIONS,
            supplierModalKey: 0,
            respModalKey: 0,
            customPagination: true
        }
    },
    mounted() {
        //
        this.findSupplier()
    },
    methods: {
        findSupplier() {
            this.listSupplier = [
                { name: 'All', id: null }
            ]
        },
        onCompleted() {
            this.fetchData()
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        deleteByGUID(val) {
            this._showConfirm('Are you sure you want to delete the recall, this cannot be undone?', { title: 'Delete Recall', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    userServices.deleteByGUID(val).then(resp => {
                        this.fetchDelay("Recall deleted successfully")
                    })
                }
            })

        },
        fetchData(val) {
            debugger
            var uId = localStorage.getItem('userId');
            var result = this.searchQuery;
            var name = null;
            var order = null;
            if (this.searchQuery.sort != undefined) {
                name = this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null;
                order = this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null;
            }
            userServices.getRecallList({
                uId,
                sortField: name,
                sortDirection: order,
                pageSize: this.searchQuery.pageSize
            }).then(resp => {
                //
                this.items = [];
                Date.prototype.yyyymmdd = function () {
                    var mm = this.getMonth() + 1; // getMonth() is zero-based
                    var dd = this.getDate();
                    console.log(this)
                    return ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + this.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                };
                if (resp.data) {
                    debugger
                    resp.data.forEach((x, i) => {
                        if (x.IsDeleted != "1") {
                            var date = "";
                            var Ndate = "";
                            if (x.ResponseDate != '01/01/1900' && x.ResponseDate != null && x.ResponseDate != undefined && x.ResponseDate != "") {
                                date = x.ResponseDate
                            }
                            else {
                                date = "";
                            }
                            if (date != "") {
                                var Tdate = new Date(x.ResponseDate);
                                var mm = Tdate.getMonth() + 1;
                                var dd = Tdate.getDate();
                                Ndate = ((dd > 9 ? '' : '0') + dd) + "/" + ((mm > 9 ? '' : '0') + mm) + "/" + Tdate.getFullYear() + " " + Tdate.getHours() + ":" + Tdate.getMinutes() + ":" + Tdate.getSeconds();
                            }
                            this.items.push({
                                recallid: x.RecallId,
                                userRecallId: x.UserRecallId,
                                name: x.supplierRecallName,
                                suppliername: x.supplierName,
                                supplierEmail: x.supplierEmail,
                                supplierRecallId: x.supplierRecallId,
                                //status : x.ResponseDate =='01/01/1900' || x.ResponseDate=='' || x.ResponseDate== null || x.ResponseDate==undefined ? 'DRAFT' : 'SENT', 
                                status: x.ResponseStatus,
                                date: Ndate,
                                documents: "",
                                Files: x.Files,
                                userId: x.userId
                            });
                        }
                    })
                    this.items.forEach((x, i) => {
                        x.Files.forEach((y, i) => {
                            if (y.FileName != "" && y.FileName != null && y.FileName != undefined) {
                                y.Ext = y.FileName.split('.')[1]
                            }
                        })
                        //
                    })

                    this.total = this.items.length;
                    if (val != null && val != undefined && val != "") {
                        this._showToast(val, { variant: 'success' })
                    }
                }
                else {
                    this._showToast('Something went wrong', { variant: 'danger' })
                }
            })
        },
        addMoreClick() {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert(this.items)
            })
        },
        EditClick(val) {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(val, this.items)
            })
        },
        addMoreRespClick(val) {
            this.respModalKey += 1
            this.$nextTick(() => {
                this.$refs.respModal.forceRerenderInsert(val)
            })
        },
        downloadFile(url) {
            // const url1 = url//window.URL.createObjectURL(new Blob([response.data]))
            // const link = document.createElement('a')
            // link.href = url1
            // link.setAttribute('download', 'file.png') 
            // document.body.appendChild(link)
            // 
            // link.click()
            //const blob = base64StringToBlob(url,'image/png');
            this.openExternalLink(url)

            // var file_path = url;
            // var a = document.createElement('A');
            // a.href = file_path;
            // //a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
            // var n  = file_path.substr(file_path.lastIndexOf('/') + 1)
            // var Nn = n.split(".")[0] + ".docx"
            // a.download = Nn;
            // 
            // document.body.appendChild(a);
            // a.click();
        },
        viewRecall() {
            //this.$refs.modal.hide()
        },
        getRecallByUId(recallId) {
            this.EditClick(recallId)
        },
    }
}